/**
 * External dependencies
 */
import { graphql, StaticQuery, Link } from 'gatsby';
import React from 'react';
import { slugify } from 'underscore.string';

/**
 * Internal dependencies
 */
import InternalLink from '../internal-link';

const CategoriesList = () => {
    return (
        <StaticQuery
            query={ graphql`
            query {
                allMarkdownRemark(limit: 1000) {
                  group(field: frontmatter___categories) {
                    fieldValue
                    totalCount
                  }
                }
            }` }
            render={ ( data ) => (
                <ul className="categories-list blog-sidebar-list">
                    { data
                    && data.allMarkdownRemark
                    && data.allMarkdownRemark.group
                    && data.allMarkdownRemark.group.map( category => (
                        <li key={ category.fieldValue }>
                            <InternalLink to={ `/category/${slugify( category.fieldValue )}/` }>
                                { category.fieldValue } ({ category.totalCount })
                            </InternalLink>
                        </li>
                    ) ) }
                    <li>
                        <InternalLink to="/articles/">
                            See All Articles
                        </InternalLink>
                    </li>
                </ul>
            ) }
        />
    );
};

export default CategoriesList;
